.user-profile {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 5rem auto 0;
  font-family: 'system-ui', sans-serif;
}

.user-profile h2 {
  text-align: center;
  color: #44ACE9;
  font-size: 28px;
  margin-bottom: 30px;
}

.profile-section {
  margin-bottom: 30px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 20px;
}

.profile-section h3 {
  color: #44ACE9;
  font-size: 20px;
  margin-bottom: 15px;
}

.user-profile p {
  font-size: 16px;
  color: #626161;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile p strong {
  font-weight: 600;
  color: #333;
}

.status {
  padding: 3px 8px;
  border-radius: 3px;
  font-weight: bold;
}

.status.active {
  background-color: #28a745;
  color: white;
}

.status.inactive,
.status.cancelled {
  background-color: #dc3545;
  color: white;
}

.status.pending {
  background-color: #ffc107;
  color: black;
}

.user-profile button {
  background-color: #44ACE9;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.user-profile button:hover {
  background-color: #3498db;
}

.user-profile button.cancel-subscription {
  background-color: #e74c3c;
}

.user-profile button.cancel-subscription:hover {
  background-color: #c0392b;
}

.user-profile button.resubscribe {
  background-color: #28a745;
}

.user-profile button.resubscribe:hover {
  background-color: #218838;
}

.loading,
.error-message {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.loading {
  color: #626161;
}

.error-message {
  color: #e74c3c;
}