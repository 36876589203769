.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'system-ui', sans-serif;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info span {
  margin-right: 15px;
}

.logout-btn {
  background-color: #44ACE9;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard-body {
  display: flex;
  height: 100%;
}

.sidebar {
  width: 250px;
  background-color: #d1cfcfa5;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 10px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
  font-size: 18px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #626161;
}

.sidebar ul li a.active {
  color: #44ACE9;
  font-weight: bold;
}

.sidebar ul li a:hover {
  font-weight: bold;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

.main-content h2 {
  color: #626161;
}

.sidebar hr {
  margin-bottom: 20px;
}

.sidebar h3 {
  font-size: 14px;
  color: #626161;
}


/* media queries */
@media screen and (max-width: 1024px) {
  .sidebar {
    width: 200px;
    padding: 20px;
  }

  .sidebar ul li {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-body {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    padding: 15px;
    border-radius: 0;
  }

  .sidebar ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .sidebar ul li {
    margin: 5px 0;
    font-size: 14px;
  }

  .sidebar h3 {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .main-content {
    padding: 15px;
  }

  .user-info {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 480px) {
  .sidebar ul li {
    font-size: 13px;
  }

  .main-content h2 {
    font-size: 20px;
  }

  .logout-btn {
    width: 100%;
  }
}