header {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 200px;
  height: auto;
  margin-bottom: 8px;
}

.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.register-btn,
.login-btn {
  background-color: #44ACE9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.user-profile-btn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.logout-btn {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

/* hamburger menu styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 2px 0;
  transition: all 0.3s ease;
}

/* mobile menu */
.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mobile-menu.active {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* media queries */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .logo-image {
    width: 150px;
  }

  /* hamburger animation */
  .hamburger.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .mobile-menu button {
    width: 100%;
    text-align: center;
  }
}