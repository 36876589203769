.steps-section {
  padding: 80px 0;
  background-color: #fff;
}

.steps-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.steps-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 60px;
  color: #333;
}

.highlight-green {
  color: #ff5fa2;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.step-card {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.step-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-card h3 {
  font-size: 1.5rem;
  margin-bottom: 12px;
  color: #333;
}

.step-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.4;
}

@media (max-width: 968px) {
  .steps-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .steps-grid {
    grid-template-columns: 1fr;
  }

  .steps-title {
    font-size: 2rem;
  }
}