.video-cleaner-container {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #00bfa6;
}

.file-upload {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.file-input {
  display: none;
}

#fileUpload+label {
  background-color: #00bfa6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.file-name-display {
  margin-left: 10px;
  padding: 10px;
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.clean-btn {
  margin-left: 10px;
  background-color: #44ace9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.checkbox-group input {
  margin-right: 10px;
}

.info-box {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  font-size: 14px;
  line-height: 1.5;
  color: #666;
}


.progress-container {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.progress-item {
  margin-bottom: 15px;
  padding: 10px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.progress-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  font-weight: 500;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}

.progress-text {
  margin-top: 6px;
  font-size: 12px;
  color: #666;
  text-align: right;
}

.file-input:disabled,
.clean-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.progress-item:last-child {
  margin-bottom: 0;
}

.copies-input {
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.copies-number {
  width: 60px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.copies-input label {
  color: #666;
  font-size: 14px;
}

.error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
}

.file-upload input:disabled,
.file-upload button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.checkbox-group input:disabled+label {
  opacity: 0.7;
  cursor: not-allowed;
}


/* media queries */
@media screen and (max-width: 768px) {
  .file-upload {
    flex-direction: column;
    align-items: stretch;
  }

  .upload-btn,
  .clean-btn {
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }

  .file-name-display {
    width: 100%;
    margin-left: 0;
  }

  .copies-input {
    flex-direction: column;
    align-items: stretch;
  }

  .copies-number {
    width: 100%;
  }

}

@media screen and (max-width: 480px) {
  .video-cleaner-container {
    padding: 15px;
  }

  .info-box {
    font-size: 12px;
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }
}