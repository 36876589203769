
.form-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 5rem;
}

.form-card h2 {
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.form-group input:hover:not(:disabled) {
  border-color: #4299e1;
}

.submit-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

.submit-btn:hover:not(:disabled) {
  background-color: #3182ce;
}

.submit-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.input-disabled {
  background-color: #f7fafc;
  cursor: not-allowed;
  opacity: 0.7;
}

.btn-disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
  opacity: 0.7;
}

.form-group input:invalid {
  border-color: #fc8181;
}

.form-group input:invalid:focus {
  box-shadow: 0 0 0 3px rgba(252, 129, 129, 0.15);
}

@media (max-width: 480px) {
  .form-card {
    padding: 1.5rem;
  }

  .form-group input {
    padding: 0.5rem;
  }

  .submit-btn {
    padding: 0.5rem;
  }
}