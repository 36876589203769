* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

body {
  background-color: #f4f4f4;
}

/* home container */
.home-container {
  width: 100%;
}

/* hero section */
.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 170px 0px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.tutorial-content {
  flex: 1;
  max-width: 600px;
}

.hero-text {
  max-width: 600px;
}

.hero-text h1 {
  font-size: 40px;
  line-height: 1.2;
  color: #333;
}

.highlight {
  color: #ff5fa2;
}

.brand-name {
  color: #44ACE9;
}

.hero-text p {
  font-size: 20px;
  color: #666;
  margin-top: 20px;
}

.get-started-btn {
  background-color: #44ACE9;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
}

/* stats section */
.stats-section {
  width: 100%;
  padding: 80px 0;
  background: #fff;
}

.stats-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #f8f9fa;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 60px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

@media (max-width: 968px) {
  .hero-section {
    flex-direction: column;
    padding: 100px 20px 0px;
  }

  .hero-content,
  .tutorial-content {
    width: 100%;
    max-width: 100%;
  }

  .hero-text {
    text-align: center;
  }

  .stats-container {
    flex-direction: column;
    text-align: center;
  }
}